@font-face {
  font-family: 'Calmius Sans High';
  src: url('./fonts/CalmiusSans-High.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* overflow-x: hidden; */
  scroll-behavior: smooth;
}

body{
  overflow-x: hidden;
  width: 100vw;
  min-height: 100vh;
  height: auto;
}

:root {
  --mainfont: 'Calmius Sans High';
  --subfont: 'Karla';

  --maincolor: #C18843;
  --maincolorlight: #FDE5B1;
  /* --maincolorlight: #ffe3c2; */
  --subcolor: #0E343D;

  --headclamp: clamp(25px, 10vw, 60px);
  --lineclamp: clamp(10px, 10vw, 50px);
}


/* Animations */


@-webkit-keyframes blink{
  0%{opacity: 1;}
  50%{opacity: 0;}
  100%{opacity: 1;}
}

@-moz-keyframes blink{
  0%{opacity: 1;}
  50%{opacity: 0;}
  100%{opacity: 1;}
}

@keyframes blink{
  0%{opacity: 1;}
  50%{opacity: 0;}
  100%{opacity: 1;}
}


.blink{
  -webkit-animation: blink 0.7s linear infinite;
  -moz-animation: blink 0.7s linear infinite;
  animation: blink 0.7s linear infinite;
}


/* Components */


/* Loader Component */


.loaderDiv{
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  font-family: var(--subfont);
  justify-content: center;
  flex-direction: column;
  color: var(--maincolor);
}
.loaderDiv>h1{
  font-family: var(--mainfont);
  font-weight: 100;
  line-height: 40px;
  font-size: 35px;
}
.loaderDiv>img{
  height: 140px;
  margin-top: 30px;
}


/* Nav Bar Component */

.mainNav{
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 20vh;
  border-bottom: 1px solid var(--maincolor);
}

.shopTimings>marquee{
  font-family: var(--subfont);
  padding: 4px;
  font-weight: 200;
  background-color: var(--maincolor);
  height: 100%;
  color: white;
}
.shopTimings>marquee>span{
  font-weight: 600;
}

.tab{
  tab-size: 10;
}

.bottomNav>a{
  height: 70px;
  width: auto;
  color: var(--maincolor);
  text-decoration: none;
  font-family: var(--subfont);
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottomNav>a>p{
  font-size: 12px;
}
.mainLogoWebsite{
  height:140%;
  width:100%;
}
.bottomNav{
  display: flex;
  align-items: center;
  padding: 0 50px;
  justify-content: space-between;
  width: 100%;
  column-gap: 50px;
  background-color: var(--maincolorlight);
}

.searchInputWeb{
  display: flex;
  width: 40%;
  position: relative;
  height: 50%;
}
.searchInputWeb>div{
  width: 100%;
  position: relative;
}
.searchInputWeb>div>div{
  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 5px black;
}
.searchInputWeb>div>div>ul{
  list-style-type: none;
  padding: 10px;
  font-family: var(--subfont);
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.searchInputWeb>div>div>ul>li{
  cursor: pointer;
}
.searchInputWeb>div>div>ul>li:hover{
  color: var(--maincolor);

}
.searchInputWeb>div>input{
  height: 100%;
  font-family: var(--subfont);
  padding-left: 7px;
  width: 100%;
  border-radius: 3px;
  border: none;
}
.searchInputWeb>svg{
  position: absolute;
  right: 0;
  font-size: 25px;
  cursor: pointer;
  top: 20%;
  margin-right: 10px;
  color: var(--maincolor);
}
.mainMenu{
  display: flex;
  list-style-type: none;
  column-gap: 20px;
}
.mainMenu>li>a{
  color: var(--maincolor);
  text-decoration: none;
}

.containerNav{
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 0.938em;
}
.flexcol{
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.columnNav {
  margin-left: -0.938em;
  margin-right: -0.938em;
}
.columnNav .rowNav{
  padding: 0 0.938em;
}
.navIcons{
  display: flex;
  color: var(--maincolor);
  font-family: var(--subfont);
  column-gap: 20px;
  flex-basis: 30%;
  justify-content: space-between;
  height: 55%;
}
.navIcons>div{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  transition: 0.5;
  cursor: pointer;
}
.navIcons>div>svg{
  transition: 0.5;

}
.navIcons>div>h4{
  text-transform: uppercase;
  font-weight: 300;
  transition: 0.5;
}
.navIcons>div:hover{
  transform: scale(1.07);
}
.navIcons>div:hover ~ .navIcons>div>h4{
  font-weight: 700;
}

.bigMenuNav{
  width: 100%;
  position: relative;
  height: 100%;
}
.bigMenuNav>ul{
  display: flex;
  list-style-type: none;
  justify-content: space-between;
  padding: 0 50px;
  align-items: center;
  height: 100%;
  font-family: var(--subfont);
  text-transform: uppercase;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 1px;  
  transition: 0.3s;

}
.bigMenuNav>ul>li>a{
  cursor: pointer;
  position: relative;
  transition: 0.3s;
  color: black;
  padding: 10px 0;
  text-decoration: none;
}
.bigMenuNav>ul>li>a::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 0px;
  bottom: 10px;
  z-index: -10;
  transition: 0.3s;
  background-color: var(--maincolor);
}


.dropdown{
  display: flex;
  column-gap: 60px;
  justify-content: space-between;
}

.bigMenuNav>ul>li>a:hover::before{
  height: 6%;
}


.navbar-links li.navbar-dropdown {
  position: relative;
}

.navbar-links li.navbar-dropdown:hover .dropdown {
  visibility: visible;
  opacity: 1;
  transform: translateY(5px);
}
.navbar-links li.navbar-dropdown .dropdown:nth-child(1), .navbar-links li.navbar-dropdown .dropdown:nth-child(1) {
  width: 35vw;
}

.navbar-links li.navbar-dropdown .dropdown {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding: 20px 20px;
  top: 100%;
  transform: translateY(30px);
  left: 0;
  background-color: #fff;
  box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 111;
  transition: 0.3s all;
}
.navbar-links li.navbar-dropdown .dropdown a {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 400;
  text-decoration: none;
  display: block;
  font-weight: 200;
  color: black;
  transition: 0.3s;
}
.navbar-links li.navbar-dropdown .dropdown a:hover {
  color: var(--maincolor);
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger-menu span {
  height: 3px;
  background-color: var(--maincolor);
  margin: 4px 0;
  width: 25px;
}

.navIconsMobile{
  display: none;
  color: var(--maincolor);
  font-family: var(--subfont);
  column-gap: 20px;
  column-gap: 0;
  flex-basis: 30%;
  margin: 10px;
  justify-content: space-between;
  height: 55%;
}
.navIconsMobile>div{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 44.49px;
  transition: 0.5;
  cursor: pointer;
}
.navIconsMobile>div>svg{
  transition: 0.5;

}
.navIconsMobile>div>h4{
  text-transform: uppercase;
  font-weight: 300;
  transition: 0.5;
}
.navIconsMobile>div:hover{
  transform: scale(1.07);
}
.navIconsMobile>div:hover ~ .navIconsMobile>div>h4{
  font-weight: 700;
}

.mainLogoMobile{
  display: none;
  height:160%;
  width:100%;
}

@media screen and (max-width: 868px) {

  .mainLogoWebsite{
    display: none;
  }
  .mainLogoMobile{
    margin-left: 10px;
    display: block;
  }
  
  .mainNav{
    height: auto;
  }

  .bigMenuNav ul {
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--maincolorlight);
    position: absolute;
    top: 100%;
    height: 100%;
    left: 0;
    width: 100%;
    display: none;
  }

  .bigMenuNav ul li {
    width: 100%;
    text-align: left;
    padding: 10px;
  }

  .hamburger-menu {
    display: flex;
  }

  .bottomNav {
    justify-content: space-between;
    width: 100%;
    padding: 0;
    padding-right: 20px;
  }

  .navIcons {
    display: none;
  }

  .searchInputWeb {
    display: none;
  }

  .bigMenuNav.open ul {
    height: auto;
    display: flex;
    z-index: 1000;
  }
  .navIconsMobile{
    height: auto;
    display: flex;
  }

  .dropdown{
    flex-wrap: wrap;
    column-gap: 20px;
    font-size: small;
  }
  .navbar-links li.navbar-dropdown .dropdown a{
    padding: 3px 0;
  }
}

@media (max-width:350px) {
  .mainLogoMobile{
    height: 150%;
  }
}

/* Footer component */

footer{
  display: flex;
  background-color: var(--maincolor);
  height: auto;
  padding: 30px 0;
  width: 100%;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  justify-content: space-around;
  color: white;
  font-family: var(--subfont);
}
footer>hr{
  width: 80%;
  height: 1px;
  background-color: white;
}

.bottomFooter{
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;
}
.bottomFooter>h4{
  font-weight: 300;
}
.bottomFooter>div{
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  text-transform: uppercase;
}
.bottomFooter>div>h4{
  font-weight: 300;
  font-size: 12px;
}

.topFooter{
  width: 80%;
  display: flex;
  justify-content: space-between;
  height: 50%;
}

.lefttopFooter{
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 20px;
}

.footerLogos{
  display: flex;
  column-gap: 10px;
}
.footerLogos>a>svg{
  background-color: white;
  padding: 7px 9px;
  cursor: pointer;
  color: var(--maincolor);
  border-radius: 50%;
  transition: 0.4s;
}
.footerLogos>a>svg:hover{
  color: white;
  background-color: var(--maincolor);
  border: 1px solid white;
}

.righttopFooter{
  display: flex;
  column-gap: 40px;
}
.righttopFooter>div{
  display: flex;
  flex-direction: column;
}
.righttopFooter>div>h4:not(:first-child){
  margin: 2px 0;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
.righttopFooter>div>h4:not(:first-child)::after{
  content: "";
  position: absolute;
  bottom: 0;
  left: -120px;
  height: 2px;
  background-color: white;
  width: 90%;
  transition: 0.3s;
}
.righttopFooter>div>h4:not(:first-child):hover::after{
  left: 0;
}


@media (max-width:700px){
  .topFooter{
    flex-direction: column;
    row-gap: 10px;
  }
  .righttopFooter{
    justify-content: space-between;
  }
}

@media (max-width:600px){
  .bottomFooter>h4{
    font-size: 10px;
  }
}

/* Seperate Item */



.pageNavigation{
  display: flex;
  font-family: var(--subfont);
  column-gap: 5px;
  margin: 20px 0;
  padding: 0 30px;
}
.pageNavigation>*{
  color: #515151;
  text-decoration: none;
  font-size: 14px;
  transition: 0.4s;
  cursor: pointer;
  font-weight: 300;
}
.pageNavigation>*:hover{
  text-decoration:underline;
}

.gallery-container {
  display: flex;
  width: 100%;
  padding: 0 25px;
  margin: 0 auto;
}

.product-images {
  position: relative;
  overflow: hidden;
}

.productImages{
  list-style-type: none;
}

.product-info {
  padding: 0 20px;
}

.price{
  font-family: var(--subfont);
  display: flex;
  align-items: center;
}

.product-info>div>h3 {
  margin-bottom: 30px;
  color: var(--maincolor);
  font-weight: 100;
  font-family: var(--mainfont);
  line-height: 20px;
  font-size: 30px;
}

.product-info>p {
  font-family: var(--subfont);
  margin-bottom: 10px;
}

.actionsItem button:first-child {
  padding: 7.5px 20px;
  margin-right: 10px;
  border: none;
  background-color: var(--maincolor);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}
.actionsItem button:first-child:hover {
  background-color: var(--maincolorlight);
  color: #000;
}
.actionsItem button:last-child{
  padding: 6px 10px;
  border-radius: 5px;
  color: var(--maincolor);
  border: 2px solid var(--maincolor);
  cursor: pointer;
  background-color: transparent;
  transition: 0.5s;
}
.actionsItem button:last-child:hover{
  background-color: var(--maincolor);
  color: white;
}

.details {
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  font-family: var(--subfont);
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.details h4 {
  margin-bottom: 10px;
}

@media (max-width:945px){
  .gallery-container{
    flex-direction: column;
  }
}


.priceBreakup{
  margin-bottom: 50px;
}

.title {
  font-size: 34px;
  padding-left: 40px;
  font-family: var(--mainfont);
  font-weight: 100;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.object-table {
  width: 70%;
  margin-left: 40px;
  font-family: var(--subfont);
  border-collapse: collapse;
  font-size: 0.875rem;
  font-weight: 300;
}

.object-table thead th {
  display: none;
}

.object-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.object-table tbody tr:hover {
  background-color: var(--maincolorlight);
}

.object-table th,
.object-table td {
  padding: 0.5rem 0.75rem;
}

.object-table td {
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.object-table td::before {
  content: attr(data-cell);
  font-weight: normal;
  width: 5rem;
  display: inline-block;
}

.object-table tbody td > div {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  flex-wrap: wrap;
}

.object-table tbody td span {
  background-color: #e0f7fa;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
}

.object-table tbody td span.type-2 {
  background-color: #fff3e0;
}

@media (min-width: 640px) {
  .object-table th {
    display: table-cell;
    font-weight: 300;
    color: #666;
    text-align: left;
  }

  .object-table td {
    display: table-cell;
  }

  .object-table td::before {
    display: none;
  }

  .object-table tbody td:first-child {
    padding-top: 0.5rem;
  }

  .object-table tbody td:last-child {
    padding-bottom: 0.5rem;
  }
}

@media (max-width:600px){
  .product-info>div>h3{
    line-height: 40px !important;
    margin-top: 30px;
    margin-bottom: 20px !important;
  }
}

@media (max-width:580px){
  .imageGalleryMain{
    flex-direction: column;
    row-gap: 20px;
  }

  .allThumbnails{
    flex-direction: row !important;
    width: 100%;
  }

  .selectedMainImage{
    width: 100% !important;
    height: auto !important
  }
}


/* Customize Form */


.instructionsCustomize{
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.instructionsCustomize>h1{
  font-family: var(--mainfont);
  font-weight: 100;
  color: var(--maincolor);
  position: relative;
}
.instructionsCustomize>h1::after{
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100px;
  background-color: var(--maincolor);
}
.instructionsCustomize>ol{
  font-family: var(--subfont);
}
.instructionsCustomize>ol>li{
  margin: 10px 0;
}
.instructionsCustomize>div{
  display: flex;
  column-gap: 10px;
  font-family: var(--subfont);
  color: rgb(79, 79, 240);
  font-size: 17px;
}
.instructionsCustomize>div>label{
  cursor: pointer;
}
.instructionsCustomize>ol>li{
  padding: 7px;
}

.customizeFormBody{
  width: 100%;
  margin-bottom: 100px;
}
.customizeFormBody>form{
  display: flex;
  flex-direction: column;
  width: 35%;
  margin-left: 30px;
  row-gap: 20px;
  font-family: var(--subfont);
}
.customizeFormBody>form>input{
  height: 30px;
  font-family: var(--subfont);
}
.customizeFormBody>form>textarea{
  height: 100px;
  resize: none;
  overflow-y: scroll;
  font-family: var(--subfont);
}
.customizeFormBody>form>button{
  width: 100px;
  padding: 7px 12px;
  border: 2px solid var(--maincolor);
  background-color: transparent;
  color: var(--maincolor);
  border-radius: 7px;
  transition: 0.4s;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
}
.customizeFormBody>form>button:hover{
  background-color: var(--maincolor);
  color: white;
}

/* Enquire Page + Form */



.enquirePageBody{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-width: 80%;
  margin: 0 auto;
  margin-top: 10px;
}
.enquirePageBody>h1{
  font-weight: 100;
  font-family: var(--mainfont);
  color: var(--maincolor);
}
.enquireFormBody{
  display: flex;
  font-family: var(--subfont);
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 100px;
  max-width: 500px;
  padding: 0 4px;
  width: auto;
}
.enquireFormBody>input:focus,
.enquireFormBody>input:active{
  border: 2px solid var(--maincolor);
  transition: background-color 5000s ease-in-out 0s;
}
.enquireFormBody>input,.enquireFormBody>textarea{
  border: 1px solid #ccc;
  padding: 10px;
  font-family: var(--subfont);
}
.enquireFormBody>textarea{
  height: 100px;
  resize: none;
}
.enquireFormBody>button{
  max-width: 100px;
  padding: 7px;
  background-color: var(--maincolor);
  border: none;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.4s;
}
.enquireFormBody>button:hover{
  border-radius: 20px;
}

.productInquiry{
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  row-gap: 10px;
}
.productInquiry>h1{
  font-family: var(--mainfont);
  font-weight: 100;
  color: var(--maincolor);
}
.productInquiry>div{
  display: flex;
  width: 100%;
}
.productInquiry>div>img{
  height: 300px;
  padding: 20px;
  border-radius: 4px;
}
.productInquiry>div>div{
  display: flex;
  flex-direction: column;
  padding: 20px;
  row-gap: 20px;
  font-family: var(--subfont);
}
.productInquiry>div>div>div{
  display: flex;
  column-gap: 50px;
  font-size: 24px;
}
.productInquiry>div>div>button{
  padding: 7px 12px;
  max-width: 100px;
  cursor: pointer;
  background-color: var(--maincolor);
  border: none;
  color: white;
  font-weight: 600;
}

@media (max-width:500px){
  .productInquiry>div{
    flex-direction: column;
  }
}


/* Also creating the thank you popup when the form is filled */

.thankyouPopup{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.thankyouPopup>div{
  position: relative;
  padding: 40px;
  width: 100%;
  font-family: var(--subfont);
  max-width: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 15px;
  background-color: white;
}
.thankyouPopup>div>a>button{
  background-color: var(--maincolor);
  padding: 7px 12px;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 6px;
}


/*  Pages   */


/*   Landing Page   */


.landSection{
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 70vh;
  position: relative;
  margin-bottom: 100px;
  position: relative;
}

.bg_1{
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1000;
}
.bg_2{
  position: absolute;
  right: 100px;
  top: 10px;
  z-index: -1000;
}

.leftLand{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  row-gap: 50px;
  padding: 0 70px;
  flex-basis: 50%;
}
.leftLand>h1::before {
  content: "Welcome to Geetha Jewellers";
  font-family: var(--subfont);
  font-size: 17px;
  font-weight: 500;
  height: 40px;
  position: absolute;
  top: -35px;
  left: 0;
  padding-left: 20px;
  color: var(--maincolor);
}

.leftLand>h1::after {
  content: "";
  position: absolute;
  top: -20px;
  left: 8px;
  height: 20px;
  border-left: 3px solid var(--maincolor);
}
.leftLand>h1{
  position: relative;
  font-family: var(--mainfont);
  color: var(--maincolor);
  font-size: clamp(25px, 13vw, 70px);
  line-height: var(--lineclamp);
  font-weight: 100;
}
.leftLand>h4{
  color: var(--maincolor);
  font-size: clamp(7px, 10vw, 20px);
  font-weight: 300;
  font-family: var(--subfont);
}
.leftLand>a{
  text-decoration: none;
}
.leftLand>a>button{
  padding: 10px;
  border-radius: 25px;
  border: 2px solid var(--maincolor);
  color: var(--maincolor);
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 160px;
  justify-content: center;
  transition: all 0.5s ease-out;
  cursor: pointer;
  background: linear-gradient(to right, var(--maincolor) 50%, #fff 50%);
  background-size: 200% 100%;
  background-position: right;
}
.leftLand>a>button:hover{
  background-position: left;
  color: white;
}
.leftLand>a>button>svg{
  font-size: 20px;
}

.rightLand{
  position: relative;
}
.rightLand>img{
  height: clamp(50%, 50vw, 110%);
  position: absolute;
}

@media (max-width:900px){
  .leftLand>h1::before{
    display: none;
  }
  .leftLand>h1::after{
    display: none;
  }
}

@media (max-width:600px){
  .leftLand{
    padding: 0 20px;
  }
}

@media (max-width:520px){
  .rightLand{
    top: 20px;
  }
  .leftLand{
    padding: 0 10px;
    top: 20px;
    justify-content: flex-start;
  }
  .leftLand>h4{
    font-size: 13px;
    padding-right: 10px;
  }
  .landSection{
    height: 50vh;
    margin-bottom: 0;
  }
  .rightLand>img{
    height: clamp(50%, 55vw, 100%);
    left: -25px !important;
  }
  
}






.siteInformation{
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 30px;
  align-items: center;
  height: auto;
  justify-content: space-between;
}

.siteInformation>img{
  height: clamp(50%, 20vw, 100%);
  width: 240px;
}

.sitemainInfo{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.sitemainInfo>img{
  flex-basis: 20%;
}
.sitemainInfo>img:first-child{
  height: 300px;
  object-fit: cover;
  border-radius: 0 100px 0 0;
  border: 2px solid var(--maincolor);
  width: 100%;
}
.sitemainInfo>img:last-child{
  height: 300px;
  width: 100%;
  border-radius: 100px 0 0 0;
  border: 2px solid var(--maincolor);
  object-fit: cover;
}
.sitemainInfo>div{
  display: flex;
  position: relative;
  bottom: 50px;
  text-align: center;
  column-gap: 100px;
  height: 50%;
  margin: auto 0;
}
.sitemainInfo>div>div{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  position: relative;
  overflow: visible;
}
.sitemainInfo>div>div:not(:last-child)::after{
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: var(--maincolor);
  right: -50px;
  top: 25px;
}
.sitemainInfo>div>div>h1{
  font-family: var(--mainfont);
  color: var(--maincolor);
  font-size: clamp(20px, 7vw, 75px);
  font-weight: 100;
}
.sitemainInfo>div>div>p{
  font-family: var(--subfont);
  color: var(--maincolor);
}

@media (max-width:985px) {
  .sitemainInfo>img{
    display: none;
  }
  .sitemainInfo>div{
    margin: 0 auto;
  }
}

@media (max-width:600px){
  .sitemainInfo>div{
    column-gap: 20px;
  }
  .sitemainInfo>div>div:not(:last-child)::after{
    right: -6px;
  }
}




.jewelPrices{
  display: flex;
  width: 100%;
  align-items: center;
  overflow-x: hidden;
  justify-content: space-between;
  height: auto;
  row-gap: 20px;
  flex-direction: column;
}
.jewelPrices>h1{
  font-family: var(--mainfont);
  color: var(--maincolor);
  font-size: var(--headclamp);
  font-weight: 100;
  line-height: 60px;
  position: relative;
}
.jewelPrices>div{
  display: flex;
}
.jewelPrices>div>div{
  position: relative;
}
.jewelPrices>div>div>div{
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  color: var(--maincolor);
  font-family: var(--subfont);
}
.jewelPrices>div>div>div>h2{
  font-size: 40px;
}
.jewelPrices>div>div>img{
  width: 300px;
  height: 150px;
}
.jewelPrices>div>div:first-child>img{
  width: 370px !important;
}

@media (max-width:690px){
  .jewelPrices>div>div:last-child>img{
    width: 200px;
    height: 100px;
  }
  .jewelPrices>div>div:first-child>img{
    width: 250px !important;
    height: 100px;
  }

  .jewelPrices>div>div>div>h2{
    font-size: 24px;
  }
}

@media (max-width:470px){
  .jewelPrices>div>div:last-child>img{
    width: 150px;
    height: 75px;
  }
  .jewelPrices>div>div:first-child>img{
    width: 200px !important;
    height: 75px;
  }

  .jewelPrices>div>div>div>h2{
    font-size: 17px;
  }
  .jewelPrices>div>div:last-child>div{
    left: 75px !important;
  }
  .jewelPrices>div>div:first-child>div{
    left: 13px !important;
  }
}



.dicoverSection{
  display: flex;
  width: 100%;
  align-items: center;
  row-gap: 60px;
  overflow-x: hidden;
  height: auto;
  height: auto;
  position: relative;
  flex-direction: column;
}
.dicoverSection::after{
  content: "";
  position: absolute;
  background-image: url("https://img.freepik.com/free-vector/gradient-golden-linear-background_52683-62246.jpg?t=st=1724503505~exp=1724507105~hmac=0abbcecc21e9a4fd5727368b2654adb38078eb9d1fb79b395e8a0863674d0f64&w=1380");
  height: 100%;
  width: 100%;
  background-size: 200px;
  background-repeat: repeat;
  opacity: 0.2;
  z-index: -100;
}
.dicoverSection>h1{
  margin-top: 20px;
  font-family: var(--mainfont);
  color: var(--maincolor);
  font-weight: 100;
  font-size: var(--headclamp);
  line-height: var(--lineclamp);
  text-align: center;
  width: 80%;
}
.dicoverSection>img{
  position: absolute;
  height: 80%;
  top: 80px;
}

.discoverBottomMobile{
  display: none;
}
.MuiMobileStepper-dots.css-1384nzf-MuiMobileStepper-dots{
  margin: 0 auto;
}

.discoverBottom{
  display: flex;
  column-gap: 15px;
  padding: 0 10px;
  padding-bottom: 30px;
  height: 100%;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  position: relative;
}
.discoverBottom>div{
  flex-basis: 25%;
  z-index: 100;
  color: var(--maincolor);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.discoverBottom>div>img{
  height: clamp(100px, 40vw, 320px);
}
.discoverBottom>div:nth-child(2){
  height: 100%;
  width: 100%;
  display: flex;
}
.discoverBottom>div:nth-child(3){
  height: 100%;
  width: 100%;
  display: flex;
}

.discoverBottom>div>h2{
  font-family: var(--mainfont);
  line-height: clamp(10px, 5vw, 30px);
  font-weight: 100;
  font-size: clamp(16px, 5vw, 30px);
  margin-bottom: 20px;
}
.discoverBottom>div>p{
  font-family: var(--subfont);
  font-weight: 100;
  text-wrap: wrap;
  max-width: 70%;
  font-size: clamp(10px, 3vw, 15px);
  margin-bottom: 20px;
}
.discoverBottom>div>a{
  font-size: 15px;
}
.discoverBottom>div>a>button{
  color: var(--maincolor);
  padding: 7px 12px;
  background-color: transparent;
  border: 2px solid var(--maincolor);
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s;
}
.discoverBottom>div>a>button:hover{
  color: white;
  background-color: var(--maincolor);
}

.css-zjf1nq-MuiPaper-root{
  background-color: transparent !important;
}

@media (max-width:1025px){
  .discoverBottom{
    left: 0;
  }
  .dicoverSection>img{
    height: 60%;
  }
}

@media (max-width:650px){
  .discoverBottom{
    display: none;
  }
  .discoverBottomMobile{
    display: flex;
  }
  .dicoverSection{
    margin-top: 30px;
    row-gap: 10px;
    height: auto;
  }
  .dicoverSection>img{
    top: 50px;
  }
}

@media (max-width:530px){
  .discoverBottom>div>a>button{
    padding: 4px;
    font-size: 10px;
  }
}

@media (max-width:455px){
  .dicoverSection{
    height: auto;
  }
}








.traditionalCared{
  display: flex;
  width: 100%;
  min-height: 80vh;
  overflow-x: hidden;
  overflow-y: hidden;
  height: auto;
  align-items: center;
  margin: 0 auto;
  justify-content: space-around;
  color: var(--maincolor);
}
.traditionalCared::after{
  content: "";
  position:absolute;
  background-image: url("https://res.cloudinary.com/drcfl15mk/image/upload/v1724502695/7476_zhny8u.jpg");
  height: 100%;
  width: 100%;
  z-index: -1000;
  background-size: 300px;
  opacity: 0.2;
}

.traditionalImage{
  position: relative;
}
.traditionalImage>img{
  /* width: clamp(100px, 100vw, 800px); */
  width: 800px;
  position: absolute;
  bottom: -300px;
  left: 30px;
  z-index: -100;
  object-fit: cover;
}
.traditionalCared>div{
  flex-basis: 50%;
  padding: 0 20px;
}

.traditionalBottom{
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 50px;
  min-height: 50vh;
  justify-content: space-around;
  position: relative;
  top: -40px;
}
.traditionalBottom>div>h1{
  font-family: var(--mainfont);
  font-weight: 100;
  font-size: 60px;
  line-height: 60px;
  max-width: 80%;
}
.traditionalBottom>div{
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}
.traditionalBottom>div>p{
  font-family: var(--subfont);
  /* max-width: 70%; */
}
.traditionalBottom>a{
  text-decoration: none;
}
.traditionalBottom>a>button{
  color: white;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  background-color: var(--maincolor);
  border: none;
  border-radius: 25px;
  transition: 0.4s;
}
.traditionalBottom>a>button:hover{
  column-gap: 17px;
}
@media (max-width:700px){
  .traditionalImage>img{
    width: 600px;
    bottom: -200px;
  }
}
@media (max-width:600px){
  .traditionalCared{
    flex-direction: column;
    min-height: 70vh;
    position: relative;
  }
  .traditionalImage>img{
    bottom: 0;
    right: -500px;
  }
}
@media (max-width:500px){
  .traditionalCared{
    min-height: 70vh;
  }
}



.cusomJewellery{
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
  justify-content: space-evenly;
  color: var(--maincolor);
  position: relative;
}
.cusomJewellery::after{
  content: "";
  background-image: url("https://img.freepik.com/free-vector/gradient-golden-linear-background_52683-62246.jpg?t=st=1724503505~exp=1724507105~hmac=0abbcecc21e9a4fd5727368b2654adb38078eb9d1fb79b395e8a0863674d0f64&w=1380");
  background-size: 200px;
  padding: 30px 0;
  opacity: 0.2;
  z-index: -1000;
  position: absolute;
  height: 100%;
  width: 100%;
}

.cutomJewelImage{
  position: relative;
  flex-basis: 30%;
}
.cutomJewelImage>img{
  width: clamp(400px, 50vw, 700px);
}
.customBottom{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  flex-basis: 30%;
  row-gap: 30px;
}
.customBottom>h1{
  font-family: var(--mainfont);
  font-size: var(--headclamp);
  font-weight: 100;
  line-height: var(--lineclamp);
}
.customBottom>h5{
  font-family: var(--subfont);
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.customBottom>p{
  font-family: var(--subfont);
}
.customBottom>h5>span{
  font-weight: 600;
  font-size: 19px;
}
.customBottom>a{
  max-width: 110px;
}
.customBottom>a>button{
  padding: 7px 15px;
  background-color: var(--maincolor);
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.4s;
}
.customBottom>a>button:hover{
  border-radius: 20px;
}
@media (max-width:650px){
  .cusomJewellery{
    flex-direction: column;
    padding: 0 30px;
  }
}






.ceotalkSection{
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 50vh;
  height: auto;
  width: 100%;
  align-items: center;
  row-gap: 20px;
  padding: 20px 0;
}
.ceotalkSection::after{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url("https://i.pinimg.com/originals/77/75/2f/77752f92fb5efa8ca9b53a21e9dc1be5.jpg");
  opacity: 0.9;
  z-index: -100;
}
.ceotalkSection>h1{
  font-family: var(--mainfont);
  color: white;
  font-weight: 100;
  text-align: center;
  line-height: var(--lineclamp);
  font-size: var(--headclamp);
}
.ceotalkSection>div{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  column-gap: 150px;
  padding: 30px;
  row-gap: 20px;
}
.ceotalkSection>div>img{
  transform: rotate(25deg);
}
.ceotalkSection>div>div{
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  z-index: 100;
  position: relative;
  row-gap: 20px;
}
.ceotalkSection>div>div>img{
  position: absolute;
  z-index: -100;
  top: 150px;
  width: 100%;
  background-size: 100px;
  opacity: 0.1;
  mix-blend-mode: multiply;
  height: 100%;
}
.ceotalkSection>div>div>p{
  font-family: var(--subfont);
  color: white;
  font-weight: 200;
  font-size: 16px;
}
.ceotalkSection>div>div>h3{
  color: white;
  font-family: var(--mainfont);
  font-weight: 100;
  line-height: 40px;
  font-size: 30px;
}
.ceotalkSection>div>div>div{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  padding-right: 50px;
  row-gap: 20px;
  font-family: var(--subfont);
  color: white;
}
.ceotalkSection>div>div>div>p{
  font-size: 20px;
}
.ceotalkSection>div>div>div>a{
  max-width: 110px;
  z-index: 1000;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}
.ceotalkSection>div>div>div>a:hover{
  letter-spacing: 3px;
}
@media (max-width:965px){
  .ceotalkSection>h1{
    margin-top: 20px;
  }
  .ceotalkSection>div{
    flex-direction: column;
    align-items: center;
  }
  .ceotalkSection>div>div{
    text-align: center;
  }
}
@media (max-width:500px){
  .ceotalkSection>div>img{
    height: 200px !important;
    width: 200px !important;
  }
}




.shopCategories{
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  overflow-x: hidden;
  background-color: var(--subcolor);
  width: 100%;
  min-height: 70vh;
}
.shopCategories>h1{
  font-size: var(--headclamp);
  font-weight: 100;
  font-family: var(--mainfont);
  line-height: var(--lineclamp);
  margin-bottom: 35px;
  color: white;
}
.shopCategories>h4{
  color: white;
  font-size: clamp(12px, 3vw, 20px);
  margin-top: 10px;
  font-weight: 300;
}

.shopCategories>div{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* height: 100%; */
  justify-content: space-between;
  column-gap: 10px;
  margin-top: 30px;
}
.shopCategories>div>a{
  text-decoration: none;
}
.shopCategories>div>a>div{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
.shopCategories>div>a>div>img{
  width: clamp(50%, 30vw, 100%);
  transition: 0.6s;
  border-radius: 20px;
}
.shopCategories>div>a>div>img:hover{
  transform: scale(1.05);
}
.shopCategories>div>a>div>h2{
  font-weight: 100;
  text-decoration: none;
  color: white;
  text-decoration: none;
  font-family: var(--subfont);
}

@media (max-width:600px){
  .shopCategories>div{
    display: grid;
    grid-template-columns: auto auto;
  }
  .shopCategories>h1{
    margin-bottom: 10px;
  }
  .shopCategories{
    padding: 10px 20px;
  }
  .shopCategories>div>a>div>img{
    border-radius: 50%;
    height: 150px;
    width: 150px;
  }
  .shopCategories>div>a>div>h2{
    font-family: var(--mainfont);
    font-weight: 100;
    line-height: 30px;
    margin-bottom: 20px;
  }
}













/*  */

/* Other Pages */


/* Custom Jewel Page */

.custom_container {
  position: relative;
  height: auto;
  width: auto;
}
.custom_container::before{
  content: "";
  background-image: url("https://img.freepik.com/free-vector/gradient-golden-linear-background_52683-62246.jpg?t=st=1724503505~exp=1724507105~hmac=0abbcecc21e9a4fd5727368b2654adb38078eb9d1fb79b395e8a0863674d0f64&w=1380");
  background-size: 200px;
  padding: 30px 0;
  opacity: 0.2;
  z-index: -100;
  position: absolute;
  height: 100%;
  width: 100%;
}

.content {
  display: flex;
  width: 90%;
  margin: 0 auto;
  column-gap: 70px;
  padding-top: 30px;
}

.image_container {
  flex: 1;
}

.customImage {
  width: 500px;
  height: 300px;
}



.custom_btn {
  background-color: var(--maincolor); 
  color: white; 
  padding: 10px 20px;
  border: none;
  border-radius: 20px 0 20px 0;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
  text-decoration: none;
  position: relative;
}

.custom_btn::before {
  content: '';
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-30%);
  width: 100%;
  z-index: -100;
  height: 100%;
  border: 2px solid var(--maincolor); 
  border-radius: 20px 0 30px 0;
  background-color: white;
}

.textCustom{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.textCustom>h1{
  font-family: var(--mainfont);
  height: 40px;
  line-height: 40px;
  font-weight: 100;
  margin-bottom: 50px;
}
.textCustom>p{
  font-family: var(--subfont);
}

.work_container {
  margin: 5em auto;
  max-width: 34.15em;
}
.checkpoint{
  max-width: 34em;
  padding-top: 2em;
  padding-bottom: 2em;
  position: relative;
}
.checkpoint>div{
  padding: 1.5em;
}
.checkpoint:nth-child(odd){
  border-left: 2.5px solid var(--maincolor);
  padding-left: 3em;
  transform: translateX(16.87em);
}
.checkpoint:nth-child(even){
  border-right: 2.5px solid var(--maincolor);
  padding-right: 3em;
  transform: translateX(-17em);
}
.checkpoint:nth-child(odd)::before,
.checkpoint:nth-child(even)::before{
  content: '';
  background: var(--maincolor);
  width: 3em;
  height: 2.5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.checkpoint:nth-child(odd)::before{
  left: 0;
}
.checkpoint:nth-child(even)::before{
  right: 0;
}
.checkpoint:nth-child(odd) div::before,
.checkpoint:nth-child(even) div::before{
  content: '';
  background-color: #fff;
  box-shadow: 0 0 0.5em var(--maincolor);
  width: 0.8em;
  height: 0.8em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}
.checkpoint:nth-child(odd) div::before{
  left: -0.5em;
}
.checkpoint:nth-child(even) div::before{
  right: -0.5em;
}

.checkpoint>div>h1{
  font-family: var(--mainfont);
  font-weight: 100;
  line-height: 40px;
}
.checkpoint>div>p{
  font-family: var(--subfont);
  font-weight: 300;
}

.inspirationWorks{
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  align-items: center;
  height: 50vh;
  margin: 40px 0;
}
.inspirationWorks>h1{
  font-family: var(--mainfont);
  font-weight: 100;
  line-height: 40px;
  color: var(--maincolor);
}
.inspirationWorks>div{
  display: flex;
  width: 80%;
  height: 100%;
  column-gap: 30px;
  margin-top: 20px;
}
.inspirationWorks>div>img{
  height: 100%;
  width: 20px;
  flex-basis: 25%;
  object-fit: cover;
  border-radius: 20px;
  transition: 0.5s;
}

.inspirationWorks>div>img:hover{
  transform: scale(1.045);
}
.inspirationWorks>div>img:not(:hover){
  transform: scale(0.95);
}

.thisIsHowWorks{
  font-family: var(--mainfont);
  font-size: 45px;
  line-height: 40px;
  font-weight: 100;
}


.faqs {
  width: 100%;
  max-width: 768px;
  font-family: var(--subfont);
}

.faqs .faq {
  padding: 15px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0 4px rgba(0, 0, 0, 0.2);
}

.faq-question{
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.faq-question>h3{
  text-transform: uppercase;
  color: var(--maincolor);
  font-weight: 600;
  font-size: 22px
}
.faq-question>h5{
  font-weight: 300;
}
.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}



@media screen and (max-width:1150px) {
  .work_container{
    text-align: center;
    width: 80vw;
  }
  .work_container .checkpoint{
    width: 100%;
    transform: none;
    padding-left: 0;
    padding-right: 0;
    border: none;
  }
  .work_container .checkpoint::before{
    width: 3px;
    height: 4em;
    top: -2em;
    left: 50%;
    transform: translateX(-50%);
  }
  .work_container .checkpoint div::before{
    top: -0.5em;
    left: 50%;
    transform: translateX(-50%);
  }  
}

@media (max-width:960px){
  .content{
    flex-direction: column-reverse;
    row-gap: 40px;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .image_container>img{
    max-width: 300px;
    max-height: 200px;
    margin-left: 0;
  }
}


/* About Page */


.aboutWrapper{
  display: flex;
  flex-direction: column;
  position: relative;
}
.aboutWrapper::after{
  content: "";
  background-image: url("https://img.freepik.com/free-vector/gradient-golden-linear-background_52683-62246.jpg?t=st=1724503505~exp=1724507105~hmac=0abbcecc21e9a4fd5727368b2654adb38078eb9d1fb79b395e8a0863674d0f64&w=1380");
  background-size: 200px;
  padding: 30px 0;
  opacity: 0.2;
  z-index: -100;
  position: absolute;
  height: 100%;
  width: 100%;
}


.about_container1 {
  display: flex;
  align-items: flex-start;
  margin-top: 50px;
  margin-left: 80px;
}

.textAbout {
  flex: 1;
  max-width: 600px;
  padding-right: 20px; 
}
.textAbout>h1{
  border-left: 3px solid var(--maincolor);
  padding-left: 6px;
  font-family: var(--subfont);
  font-weight: 800;
  color: var(--maincolor);
  font-size: 15px;
}
.textAbout > h2{
  font-family: var(--mainfont);
  font-size: 40px;
  font-weight: 100;
  line-height: 40px;
  margin: 10px 0;
  color: var(--maincolor);
}
.textAbout > p {
  font-family: var(--subfont);
  margin-top: 20px;
}

.about {
  max-width: 40%;
  margin-left: 40%;
  border-radius: 20px;
}

.about2 {
  width: 55%;
  margin-left: 5%;
  margin-top: 20px;
}
.about_container2 {
  display: flex;
  align-items: flex-start;
  margin-top: 80px;
  margin-left: 80px;
}
.about_container2 > img {
  flex: 1;
}
.about2p {
  flex: 1;
} 
.about2p>h1{
  font-family: var(--mainfont);
  font-weight: 100;
  line-height: 40px;
  color: var(--maincolor);
  font-size: 40px;
  margin-bottom: 40px;
}
.about2p>p{
  font-family: var(--subfont);
}


.about_container3{
  display: flex;
  margin-left: 100px;
  column-gap: 30px;
}
.servicesTextLeft{
  display: flex;
  height: 100%;
  flex-direction: column;
  row-gap: 20px;
}
.servicesTextLeft>h1{
  font-family: var(--mainfont);
  color: var(--maincolor);
  line-height: 40px;
  font-weight: 100;
  font-size: 40px;
}
.servicesTextLeft>p{
  font-family: var(--subfont);
  margin-top: 10px;
}


.about_container4{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 100px;
  margin-left: 100px;
}
.about_container4>h1{
  font-family: var(--mainfont);
  font-weight: 100;
  color: var(--maincolor);
  line-height: 40px;
  font-size: 40px;
}
.about_container4>div{
  display: flex;
  column-gap: 40px;
}
.about_container4>div>div{
  display: flex;
  row-gap: 20px;
  padding-top: 40px;
  flex-direction: column;
}
.about_container4>div>div>div{
  display: flex;
  column-gap: 20px;
  font-family: var(--subfont);
}
.about_container4>div>div>div>svg{
  color: var(--maincolor);
  font-size: 20px;
}
.about_container1>div>iframe{
  width: 500px;
  height: 350px;
}

@media (max-width:1290px){
  .about_container3{
    flex-direction: column;
    margin-left: 20px;
    row-gap: 50px;
  }
}

@media (max-width:950px){
  .about_container1, .about_container2{
    flex-direction: column;
    row-gap: 50px;
    margin: 0 auto;
    align-items: center;
  }
  .about_container2{
    flex-direction: column-reverse;
  }
}

@media (max-width:680px){
  .about_container4>div{
    flex-direction: column;
  }
  .about_container1 iframe{
    width: 100%;
    height: 100%;
  }
}

@media (max-width:600px){
  .textAbout{
    padding-left: 20px;
  }
  .about2p{
    padding-left: 20px;
  }
}

@media (max-width:580px){
  .about_container4 iframe{
    width: 330px !important;
  }
}

@media (max-width:500px) {
  .about_container4{
    margin-left: 30px;
  }
}





/* work with us  */


.workWrapper{
  display: flex;
  flex-direction: column;
  position: relative;
}
.workWrapper::before{
  content: "";
  background-image: url("https://img.freepik.com/free-vector/gradient-golden-linear-background_52683-62246.jpg?t=st=1724503505~exp=1724507105~hmac=0abbcecc21e9a4fd5727368b2654adb38078eb9d1fb79b395e8a0863674d0f64&w=1380");
  background-size: 200px;
  padding: 30px 0;
  opacity: 0.2;
  z-index: -100;
  position: absolute;
  height: 100%;
  width: 100%;
}

.working_container{
  display: flex;
  align-items: flex-start;
  margin-top: 70px;
  padding: 0 30px;
  margin-bottom: 30px;
}
.right_content>img{
  width: clamp(50%, 90vw, 100%);
}
.left_content{
  flex: 1;
}
.right_content{
  flex: 1;
  margin-left: 80px;
}
.workwithusImage{
  margin-top: 50px;
  width: 60%;
}
.maintitleWork{
  font-family: var(--mainfont);
  color: var(--maincolor);
  font-weight: 100;
  font-size: 45px;
}
.workTextTop>p{
  font-family: var(--subfont);
}
.whyWorkWith{
  font-family: var(--mainfont);
  font-weight: 100;
  color: var(--maincolor);
}

.left_content>h1{
  margin-bottom: 20px;
  font-family: var(--mainfont);
  font-size: 40px;
  font-weight: 100;
  line-height: 50px;
}

.left_content>div>p:first-child{
  font-family: var(--subfont);
  font-weight: 700;
}
.left_content>div>p:nth-child(2){
  font-family: var(--subfont);
}

@media (max-width:790px){
  .right_content{
    display: none;
  }
}

@media (max-width:640px){
  .workwithusImage{
    width: 90%;
  }
}

@media (max-width:600px){
  .workWithImage{
    width: 60% !important;
  }
}



/* product page */


/* Template Page */

.jewelHeader{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.jewelHeader>h1{
  font-family: var(--mainfont);
  font-weight: 100;
  padding-left: 10px;
}
.jewelHeader>img{
  height: 300px;
  width: 80%;
  margin: 0 auto;
  object-fit: cover;
}
.jewelHeader>p{
  font-family: var(--subfont);
  font-size: 14px;
  font-weight: 300;
}

.clearBtn{
  padding: 3px 5px;
  background-color: var(--maincolor);
  color: white;
  border-radius : 2px;
  font-family: var(--subfont);
  font-weight: 600;
  cursor: pointer;
  border-color: transparent;
  transition: 0.3s;
}
.clearBtn:hover{
  transform: scale(0.95);
}

.prod_filter{
  display: flex;
  width: 100%;
  max-width: 1000px;
  justify-content: space-evenly;
  margin-top: 40px;
}
.prod_filter p{
  font-family: var(--subfont);
}

.prod_display{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 50px;
  column-gap: 50px;
  row-gap: 30px;
  align-items: center;
  justify-content: center;
}

.itemBox{
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  row-gap: 5px;
  color: black;
  text-decoration: none;
}
.itemBox>div{
  background-color: rgba(128, 128, 128, 0.08);
}

.itemBox > div > img:first-child {
  display: block;
}
.itemBox > div > img:nth-child(2) {
  display: none;
}
.itemBox > div:hover > img:first-child {
  display: none;
}
.itemBox > div:hover > img:nth-child(2) {
  display: block;
}

.itemBox>div>img{
  object-fit: contain;
  width: 210px;
  mix-blend-mode: multiply;
  height: 240px;
}
.itemBox>h3{
  font-family: var(--subfont);
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
}
.itemBox>h5{
  font-family: var(--subfont);
  font-size: 16px;
  font-weight: 200;
}

.filterPopups{
  position: absolute;
  top: 20px;
  left: 0;
  border: 2px solid var(--maincolor);
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  z-index: 1;
  box-shadow: 0 2px 5px var(--maincolor);
  display: grid;
  font-family: var(--subfont);
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
}
.filterPopups>div{
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  flex-basis: 33%;
  width: 100%;
}
.filterPopups>div>p:not(:first-child){
  cursor: pointer;
  font-weight: 300;
}
.filterPopups>div>p:not(:first-child):hover{
  color: var(--maincolor);
}
.filterPopups>div>p>strong{
  font-weight: 700;
  text-transform: uppercase;
  color: var(--maincolor);
}
.filterPopups input[type="checkbox"], .filterWeightPopups input[type="checkbox"], .filterPricePopups input[type="checkbox"]{
  visibility: hidden;
  display: none;
}
.filterPopups label{
  font-weight: 300;
}
.filterPopups label:hover{
  color: var(--maincolor);
}
.filterWeightPopups,.filterPricePopups{
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px;
  border: 2px solid var(--maincolor);
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  z-index: 1;
  box-shadow: 0 2px 5px var(--maincolor);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
}
.filterWeightPopups>div{
  display: flex;
  column-gap: 10px;
  font-family: var(--subfont);
  font-weight: 300;
  align-items: center;
  justify-content: flex-start;
  width: 125px;
}
.filterWeightPopups>div>label:hover, .filterPricePopups>div>label:hover{
  color: var(--maincolor);
}
.filterPricePopups>div{
  display: flex;
  column-gap: 10px;
  font-family: var(--subfont);
  font-weight: 300;
  align-items: center;
  justify-content: flex-start;
  width: 120px;
}

.displayFilters{
  max-width: 1000px;
  margin: 0 auto;
  padding: 10px 0;
  font-family: var(--subfont);
  display: flex;
  column-gap: 20px;
  align-items: center;
}
.displayFilters>h3{
  color: var(--maincolor);
  opacity: 0.8;
  text-transform: uppercase;
  font-weight: 300;
}
.selected-filters{
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
}
.displayFilters button{
  padding: 5px;
  border: 2px solid var(--maincolor);
  background-color: var(--maincolorlight);
  text-transform: capitalize;
}


@media (max-width:850px){
  .filterPopups,.filterWeightPopups,.filterPricePopups{
    font-size: 10px;
  }
  .filterPopups{
    width: 300px;
  }
  .filterPricePopups{
    width: 100px;
  }
  .filterWeightPopups{
    width: 120px;
  }
}

@media (max-width:750px){
  .jewelHeader>img, .jewelHeader>p{
    display: none;
  }
}

@media (max-width:620px){
  .prod_filter{
    align-items: center;
  }
  .prod_filter>p, .prod_filter>div>p{
    font-size: 12px;
  }
}

@media (max-width:550px){
  .prod_display{
    display: grid;
    padding: 0 5px;
    grid-template-columns: auto auto;
  }
  .itemBox>div>img{
    height: 200px;
    width: 140px;
  }
}



/* Chitfund Page */


.chitWrapper{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  position: relative;
}
.chitWrapper::after{
  content: "";
  background-image: url("https://img.freepik.com/free-vector/gradient-golden-linear-background_52683-62246.jpg?t=st=1724503505~exp=1724507105~hmac=0abbcecc21e9a4fd5727368b2654adb38078eb9d1fb79b395e8a0863674d0f64&w=1380");
  background-size: 200px;
  padding: 30px 0;
  opacity: 0.2;
  z-index: -100;
  position: absolute;
  height: 100%;
  width: 100%;
}

.landChitfund{
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
  row-gap: 20px;
  align-items: center;
  text-align: center;
  height: auto;
}
.landChitfund>h1{
  color: var(--maincolor);
}
.landChitfund>img{
  max-height: 350px;
  height: auto;
  width: 90%;
  object-fit: contain;
}

.aboutPlanChit{
  display: flex;
  width: 80%;
  height: auto;
  margin: 20px auto;
  justify-content: space-between;
  column-gap: 30px;
}
.chitPlanLeft{
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  row-gap: 50px;
}
.chitPlanLeft>h2{
  color: var(--maincolor);
}
.chitPlanLeft>p{
  font-family: var(--subfont);
}
.chitPlanRight{
  width: 460px;
}
.chitPlanLeft>button{
  width: 100px;
  padding: 7px 5px;
  background-color: var(--maincolor);
  border: none;
  color: white;
  font-family: var(--subfont);
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
}
.chitPlanLeft>button:hover{
  width: 120px;
}
.chitTable{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin: 20px auto;
  width: 80%;
}
.chitTable>h1{
  color: var(--maincolor);
  text-align: center;
}

#planMain {
  width: 70%;
  margin: 0 auto;
  font-family: var(--subfont);
  border-collapse: collapse;
  font-size: 0.875rem;
  font-weight: 300;
}

#planMain td, #planMain th {
  border: 1px solid #ddd;
  padding: 8px;
}

#planMain tr:nth-child(even){
  background-color: #f2f2f2;
}

#planMain tr:hover {
  background-color: var(--maincolorlight);
}

#planMain th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--maincolor);
  color: white;
}

.highlightTot{
  font-weight: 600 !important;
}

.chitTerms{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin: 50px auto;
  width: 80%;
}
.chitTerms>h1{
  color: var(--maincolor);
}
.chitTerms>ul{
  font-family: var(--subfont);
  font-size: 18px;
  margin-top: 10px;
  font-weight: 300;
}
.chitTerms>ul>li{
  margin-bottom: 10px;
}

@media (max-width:1025px) {
  .aboutPlanChit{
    flex-direction: column;
    row-gap: 50px;
  }
}

@media (max-width:730px){
  #planMain{
    width: 90%;
  }
}

@media (max-width:550px){
  .chitPlanRight{
    width: 360px;
  }
  
}

@media (max-width:425px){
  .chitPlanRight{
    width: 280px;
  }
  .chitTable{
    overflow-x: auto;
  }
}





/* Admin page */



.admin-top {
  margin-top: 50px;
  max-width: 1300px;
  margin-left: 100px;
  font-family: var(--subfont);
}

.admin-top > p {
  margin-top: 20px;
  font-size: 18px;
  margin-bottom: 10px;
}

.flex-item {
  position: relative;
  display: inline-block;
}

.flex-item img {
  margin-left: 30px;
  align-items: center;
  mix-blend-mode: multiply;
  display: flex;
  width: 85%;
  height: 50%;
  justify-content: center;
  margin-top: 10px;
  border-radius: 12px;
}

.item-body {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 25px;
  text-align: center;
  cursor: pointer;
  font-weight: 450;
}

.item-stat {
  display: inline-block;
  width: 323px;
  cursor: pointer;
  font-size: 15px;
  text-align: center;
  line-height: 20px;
}

.item-check {
  display: inline-block;
  width: 150px;
  background-color: #ff9900;
  color: white;
  height: 40px;
  border: 1px solid;
  font-size: 15px;
  cursor: pointer;
}

.flex-item {
  width: 360px;
  height: 440px;
  border: 1px solid #ccc;
  margin: 20px;
  padding: 10px;
  box-sizing: border-box;
  font-family: var(--subfont);
}

.item-name {
  text-decoration: none;
  color: #454545;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}

.item__layout {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  justify-content: center;
}

.button2 {
  background-color: black;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 38px;
  width: 112px;
  font-size: 18px;
  margin-top: 15px;
  margin-left: 110px;
}

@media (max-width: 1135px) {
  .item__layout {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 740px) {
  .item__layout {
    grid-template-columns: 1fr;
    place-items: center;
  }
  .admin-top{
    margin-left: 0;
  }
}

.price-tab{
  margin-top: 50px;
  text-align: center;
  font-family: var(--subfont);
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-item {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.form-item label {
  margin-right: 10px;
  font-weight: bold;
}

.input-group {
  display: flex;
  align-items: center;
}

.input-group .form-field {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 200px;
}

.input-group span {
  margin-left: 5px;
  font-size: 14px;
  color: #99a3ba;
  background-color: #eef4ff;
  border: 1px solid #cdd9ed;
  padding: 8px;
}

.prod-cont{
  margin-top: 50px;
  margin-bottom: 30px;
}











/* Profile Page */



.profileMain{
  display: flex;
  flex-direction: column;
  height: auto;
}
.profileTop{
  display: flex;
  height: 30vh;
  font-family: var(--subfont);
  align-items: flex-end;
  padding: 30px;
  column-gap: 20px;
  overflow: visible;
  position: relative;
}
.profileTop>h1{
  color: white;
}
.profileTop>p{
  color: white;
  font-size: 12px;
  padding-left: 20px;
}
.profileAbsolute>div>svg{
  font-size: 60px;
  margin-bottom: 10px;
}
.profileAbsolute>div{
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3px;
}
.profileAbsolute>div>h5{
  /* color: var(--subcolor); */
  color: black;
}

.profileMiddle{
  display: flex;
  padding: 30px;
}
.profileMiddle>div:first-child{
  display: flex;
  flex-basis: 20%;
  position: relative;
  padding: 25px;
  height: 250px;
  background-color: white;
  color: black;
  font-family: var(--subfont);
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.354);
  padding: 40px;
}
.profileMiddle>div>p{
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
  cursor: pointer;
}
.profileMiddle>h2{
  font-size: 26px;
  overflow: visible;
  position: relative;
}
.profileMiddle>h2::before{
  content: "";
  position: absolute;
  height: 2px;
  background-color: gray;
  width: 120px;
  left: 34px;
  bottom: -5px;
}

.profileFavorites{
  flex-basis: 70%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: 20px;
  padding: 30px;
  height: auto;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.profileFavorites>h2{
  font-family: var(--subfont);
  font-weight: 700;
  color: var(--maincolor);
  font-size: 20px;
  position: relative;
  cursor: pointer;
}
.profileFavorites>h2::before{
  position: absolute;
  bottom: -7px;
  z-index: 100;
  content: '';
  background-color: var(--maincolor);
  height: 2px;
  width: 13%;
}

.profileBottom{
  display: flex;
  padding: 20px;
  flex-direction: column;
  padding-left: 50px;
}
.profileBottom>h2{
  font-size: 26px;
  overflow: visible;
  position: relative;
}
.profileBottom>h2::before{
  content: "";
  position: absolute;
  height: 2px;
  background-color: gray;
  width: 60px;
  left: 14px;
  bottom: -5px;
}

.itemList{
  display: flex;
  margin: 20px 0;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}


.emptyProfile{
  min-height: 80vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  row-gap: 10px;
  font-family: var(--subfont);
  width: 100%;
}
.emptyProfile>img{
  mix-blend-mode: multiply;
}
.emptyProfile>h1{
  font-weight: 800;
}
.emptyProfile>h3{
  font-weight: 400;
}

@media (max-width:540px){
  .emptyProfile>img{
    width: 100%;
  }
}


/* Login Page */



.loginWrapper{
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginWrapper::before{
  content: "";
  background-image: url("https://img.freepik.com/free-vector/gradient-golden-linear-background_52683-62246.jpg?t=st=1724503505~exp=1724507105~hmac=0abbcecc21e9a4fd5727368b2654adb38078eb9d1fb79b395e8a0863674d0f64&w=1380");
  background-size: 200px;
  padding: 30px 0;
  opacity: 0.2;
  z-index: -100;
  position: absolute;
  height: 100%;
  width: 100%;
}

.mainLoginContainer{
  margin: 0 auto;
  display: grid;
  font-family: var(--subfont);
  grid-template-columns: 45% 55%;
  place-items: center;
  background: white;
  width: min(800px, 95%);
  border: 2px solid var(--maincolor);
  height: auto;
  border-radius: 20px;
}

.left-side-signup {
  height: 100%;
  width: 100%;
  background-image: url(https://imgcdn.floweraura.com/royal-blue-zari-jewellery-box-ven-38-kcjbmea005-A_0.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  pointer-events: none;
  border-radius: 20px 0 0 20px;
}
.left-side {
  height: 100%;
  width: 100%;
  background-image: url(https://www.beyondthetaj.com/wp-content/uploads/2019/02/Mughal-Jewellery.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  pointer-events: none;
  border-radius: 20px 0 0 20px;
}

.right-side {
  padding: 60px;
}

.btn-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-bottom: 32px;
}

.btn-group .btn {
  display: flex;
  align-items: center;
  column-gap: 4px;
  width: max-content;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 8px 6px;
  border: 2px solid #6b7280;
  border-radius: 5px;
  background-color: #f6f6f6;
  transform: scale(1);
  cursor: pointer;
  transition: transform 0.1s ease, background-color 0.5s, color 0.5s;
}

.btn-group .btn:active {
  transform: scale(0.97);
}

.btn-group .btn:hover {
  background-color: #000;
  color: #eee;
}

.btn img {
  width: 16px;
  height: 16px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f6f6;
  border-radius: 50%;
  padding: 2px;
}

/* OR */

.or {
  position: relative;
  text-align: center;
  margin-bottom: 24px;
  font-size: 1rem;
  font-weight: 600;
}

.or::before,
.or::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 40%;
  height: 1px;
  background: #000;
}

.or::before {
  left: 0;
}

.or::after {
  right: 0;
}


.right-side> form > input {
  width: 100%;
  padding: 12px 20px;
  border: 2px solid #ccc;
  outline: 0;
  border-radius: 5px;
  background-color: transparent;
  margin: 4px 0 18px;
  font-size: 0.85rem;
  font-weight: 600;
  transition: all 0.5s;
}

.right-side> form > input:focus {
  border: 2px solid var(--maincolor);
}

.right-side> form > input:-webkit-autofill,
.right-side> form > input:-webkit-autofill:hover,
.right-side> form > input:-webkit-autofill:focus,
.right-side> form > input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
}

.right-side> form > label {
  font-weight: 600;
  font-size: 0.9rem;
}

.login-btn {
  width: 100%;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 8px 24px;
  margin: 12px 0 24px;
  border: 2px solid var(--maincolor);
  border-radius: 5px;
  cursor: pointer;
  color: var(--maincolor);
  transition: all 0.5s;
}

.login-btn:hover {
  background-color: var(--maincolor);
  color: #eee;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.links>a{
  color: black;
  text-decoration: none;
  transition: 0.5s;
}
.links>a:hover{
  text-decoration: underline;
}

@media (max-width:775px){
  .loginWrapper{
    height: auto;
  }
  .mainLoginContainer{
    grid-template-rows: 50% 50%;
    grid-template-columns: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    height: 100vh;
  }
  .left-side-signup{
    border-radius: 20px;
  }
  .right-side{
    padding: 0 30px;
  }
}



/* Search Page */



.searchedContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  row-gap: 25px;
}
.searchedContainer>img{
  height: 40vh;
  width: 90%;
  object-fit: cover;
}
.searchedContainer>h4{
  font-family: var(--subfont);
  font-weight: 300;
  text-align: left;
  width: 90%;
}
